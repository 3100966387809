<template>
  <v-container>
    <v-row class="row search spacer-md">
      <!-- Milchverwerter -->
      <v-col cols="12" sm="6" md="4" lg="2">
        <search-dairy ref="search-dairy" @search="search" />
      </v-col>
      <!-- Kontakt-->
      <v-col cols="12" sm="6" md="4" lg="2">
        <search-contact ref="search-contact" @search="search" />
      </v-col>
      <!-- MPD1 Meldungen -->
      <v-col cols="12" sm="6" md="4" lg="2">
        <search-production-report ref="search-production-report" @search="search" />
      </v-col>
      <!-- Rechtliche Person -->
      <v-col cols="12" sm="6" md="4" lg="2">
        <search-legal-entity ref="search-legal-entity" @search="search" />
      </v-col>
      <!-- Betrieb -->
      <v-col cols="12" sm="6" md="4" lg="2">
        <search-farm ref="search-farm" @search="search" />
      </v-col>
    </v-row>

    <v-btn color="primary" @click="search"><span v-html="$t('production_forms_reports_search')" /></v-btn>
    <v-btn color="secondary" @click="reset"><span v-html="$t('production_forms_reports_reset')" /></v-btn>
    <v-btn color="secondary" @click="download" :loading="loadingDownload"><span v-html="$t('production_forms_reports_download')" /></v-btn>

    <v-data-table-server
      :headers="fields"
      :items="items"
      :fields="fields"
      :loading="loading"
      :items-length="totalElements"
      @update:itemsPerPage="routerPushSize"
      @update:page="routerPushPage"
      @update:sort-by="routerPushDTSort"
      :sort-by="toDTSort(query.sort)"
      :page="getDTPageFromRoute(query.page)"
      :items-per-page="query.size"
      :items-per-page-options="$itemsPerPageOptions"
      :multi-sort="true"
      density="compact"
    >
      <template v-slot:[`item.mbhId`]="{ item }">
        <template v-if="$privileges.has({ path: '/dairies', permission: 'search' })">
          <router-link
            :to="{
              name: 'masterdata_dairies_edit',
              params: { id: item.dairyId, tab: 'overview' }
            }"
          >
            {{ item.mbhId ? item.mbhId : $t('none') }}
          </router-link>
        </template>
        <template v-else>
          {{ item.mbhId ? item.mbhId : $t('none') }}
        </template>
      </template>

      <template v-slot:[`item.countCreated`]="{ item }">
        <template v-if="$privileges.has({ path: '/productionForms', permission: 'read' })">
          <router-link
            :to="{
              name: 'productionForms_read',
              query: { status: 'CREATED', size: 50 }
            }"
          >
            {{ item.countCreated }}
          </router-link>
        </template>
        <template v-else>
          {{ item.countCreated }}
        </template>
      </template>

      <template v-slot:[`item.countInProgress`]="{ item }">
        <template v-if="$privileges.has({ path: '/productionForms', permission: 'read' })">
          <router-link
            :to="{
              name: 'productionForms_read',
              query: { status: 'IN_PROGRESS', size: 50 }
            }"
          >
            {{ item.countInProgress }}
          </router-link>
        </template>
        <template v-else>
          {{ item.countInProgress }}
        </template>
      </template>

      <template v-slot:[`item.countImported`]="{ item }">
        <template v-if="$privileges.has({ path: '/productionForms', permission: 'read' })">
          <router-link
            :to="{
              name: 'productionForms_read',
              query: { status: 'IMPORTED', size: 50 }
            }"
          >
            {{ item.countImported }}
          </router-link>
        </template>
        <template v-else>
          {{ item.countImported }}
        </template>
      </template>

      <template v-slot:[`item.countCorrection`]="{ item }">
        <template v-if="$privileges.has({ path: '/productionForms', permission: 'read' })">
          <router-link
            :to="{
              name: 'productionForms_read',
              query: { status: 'CORRECTION', size: 50 }
            }"
          >
            {{ item.countCorrection }}
          </router-link>
        </template>
        <template v-else>
          {{ item.countCorrection }}
        </template>
      </template>

      <template v-slot:[`item.countValidation`]="{ item }">
        <template v-if="$privileges.has({ path: '/productionForms', permission: 'read' })">
          <router-link
            :to="{
              name: 'productionForms_read',
              query: { status: 'VALIDATION', size: 50 }
            }"
          >
            {{ item.countValidation }}
          </router-link>
        </template>
        <template v-else>
          {{ item.countValidation }}
        </template>
      </template>

      <template v-slot:[`item.countValidated`]="{ item }">
        <template v-if="$privileges.has({ path: '/productionForms', permission: 'read' })">
          <router-link
            :to="{
              name: 'productionForms_read',
              query: { status: 'VALIDATED', size: 50 }
            }"
          >
            {{ item.countValidated }}
          </router-link>
        </template>
        <template v-else>
          {{ item.countValidated }}
        </template>
      </template>

      <template v-slot:[`item.countTransferSap`]="{ item }">
        <template v-if="$privileges.has({ path: '/productionForms', permission: 'read' })">
          <router-link
            :to="{
              name: 'productionForms_read',
              query: { status: 'CLOSED_NO_CREDITOR', size: 50 }
            }"
          >
            {{ item.countTransferSap }}
          </router-link>
        </template>
        <template v-else>
          {{ item.countTransferSap }}
        </template>
      </template>

      <template v-slot:[`item.countClosed`]="{ item }">
        <template v-if="$privileges.has({ path: '/productionForms', permission: 'read' })">
          <router-link
            :to="{
              name: 'productionForms_read',
              query: { status: 'CLOSED', size: 50 }
            }"
          >
            {{ item.countClosed }}
          </router-link>
        </template>
        <template v-else>
          {{ item.countClosed }}
        </template>
      </template>

      <template v-slot:[`item.validFrom`]="{ item }">
        {{ item.validFrom ? $moment(item.validFrom).format('DD.MM.YYYY') : '' }}
      </template>
      <template v-slot:[`item.validUntil`]="{ item }">
        {{ item.validUntil ? $moment(item.validUntil).format('DD.MM.YYYY') : '' }}
      </template>
      <template v-slot:[`item.quantityProduction`]="{ item }">
        {{ $formatNumber(item['quantityProduction'], 1) }}
      </template>
      <template v-slot:[`item.quantityProcessing`]="{ item }">
        {{ $formatNumber(item['quantityProcessing'], 1) }}
      </template>
      <template v-slot:[`item.quantityDiff`]="{ item }">
        {{ $formatNumber(item['quantityDiff'], 1) }}
      </template>
    </v-data-table-server>
  </v-container>
</template>

<script lang="ts">
import { backendService } from '@/services/BackendService'
import { apiURL } from '@/main'
import { Term } from '@/services/term'
import { showError } from '@/services/axios'
import { fileCreator } from '@/services/axios'
import searchFarm from '@/components/searchCards/searchFarm.vue'
import { useSearchFarmStore } from '@/store/SearchFarmStore'
import searchDairy from '@/components/searchCards/searchDairy.vue'
import { useSearchDairyStore } from '@/store/SearchDairyStore'
import searchContact from '@/components/searchCards/searchContact.vue'
import { useSearchContactStore } from '@/store/SearchContactStore'
import searchLegalEntity from '@/components/searchCards/searchLegalEntity.vue'
import { useSearchLegalEntityStore } from '@/store/SearchLegalEntityStore'
import SearchProductionReport from '@/components/searchCards/searchProductionReport.vue'
import { DTSHeader } from '@/services/BackendService'

import { defineComponent } from 'vue'
export default defineComponent({
  name: 'production_forms_reports',
  data() {
    return {
      selected: [],
      items: [],
      loading: false,
      loadingDownload: false,
      initialized: false,

      fields: <DTSHeader[]>[
        // MBH-ID
        {
          title: this.$t('production_forms_reports_mbhIdent'),
          key: 'mbhId',
          sortable: true,
          align: 'left'
        },
        // Name / Firma
        {
          title: this.$t('production_forms_reports_name_company'),
          key: 'name',
          sortable: true
        },
        // Vorname / Zusatz
        {
          title: this.$t('production_forms_reports_first_name_addition'),
          key: 'firstNameAddition',
          sortable: true
        },
        // Periodizität
        {
          title: this.$t('production_forms_reports_periodicity'),
          key: 'formPeriod' + this.$getUpLangKey(),
          sortable: true
        },
        // Gesuchsperiode von
        {
          title: this.$t('production_forms_reports_period'),
          key: 'validFrom',
          sortable: true
        },
        // bis
        {
          title: this.$t('production_forms_reports_until'),
          key: 'validUntil',
          sortable: true
        },
        // Neu erstellt
        {
          title: this.$t('production_forms_reports_new'),
          key: 'countCreated',
          sortable: true,
          align: 'end'
        },
        // In Bearbeitung
        {
          title: this.$t('production_forms_reports_in_progress'),
          key: 'countInProgress',
          sortable: true,
          align: 'end'
        },
        // Eingelesen
        {
          title: this.$t('production_forms_reports_data_read'),
          key: 'countImported',
          sortable: true,
          align: 'end'
        },
        // In Korrektur
        {
          title: this.$t('production_forms_reports_correction'),
          key: 'countCorrection',
          sortable: true,
          align: 'end'
        },
        // In Prüfung TSM
        {
          title: this.$t('production_forms_reports_tsm_in_examination'),
          key: 'countValidation',
          sortable: true,
          align: 'end'
        },
        // Geprüft
        {
          title: this.$t('production_forms_reports_examined'),
          key: 'countValidated',
          sortable: true,
          align: 'end'
        },
        // Abgeschlossen (Zulage ausstehend)
        {
          title: this.$t('production_forms_reports_completed_zulagen_pending'),
          key: 'countTransferSap',
          sortable: true,
          align: 'end'
        },
        // Abgeschlossen
        {
          title: this.$t('production_forms_reports_completed'),
          key: 'countClosed',
          sortable: true,
          align: 'end'
        },
        // Menge MPD1
        {
          title: this.$t('production_forms_reports_quantity_mpd1'),
          key: 'quantityProduction',
          sortable: true,
          align: 'end'
        },
        // Tierart
        {
          title: this.$t('production_forms_reports_animal_type'),
          key: 'animalType' + this.$getUpLangKey(),
          sortable: true
        },
        // Status TSM1
        {
          title: this.$t('production_forms_reports_status_tsm1'),
          key: 'processingFormStatus' + this.$getUpLangKey(),
          sortable: true
        },
        // Menge TSM1
        {
          title: this.$t('production_forms_reports_quantity_tsm1'),
          key: 'quantityProcessing',
          sortable: false,
          align: 'end'
        },
        // Diff. (MPD1 - TSM1)
        {
          title: this.$t('production_forms_reports_status_quantityCalculated_column'),
          key: 'quantityDiff',
          sortable: true,
          align: 'end'
        }
      ],
      totalElements: 0
    }
  },
  computed: {
    term(): any {
      return [
        ...Term.buildTermItems(useSearchDairyStore(), useSearchDairyStore().items),
        ...Term.buildTermItems(useSearchContactStore(), useSearchContactStore().items),
        ...Term.buildTermItems(useSearchFarmStore(), useSearchFarmStore().items),
        ...Term.buildTermItems(useSearchLegalEntityStore(), useSearchLegalEntityStore().items)
      ]
    },
    params() {
      return {
        statusFrom: this.$store.state.SearchProductionReportStore.statusFrom,
        statusTo: this.$store.state.SearchProductionReportStore.statusTo,
        correction: this.$store.state.SearchProductionReportStore.correction,
        validFrom: this.$store.state.SearchProductionReportStore.validFrom,
        validUntil: this.$store.state.SearchProductionReportStore.validUntil,
        animalTypeId: this.$store.state.SearchProductionReportStore.animalTypeId
      }
    },
    query(): any {
      return {
        term: Term.stringify(this.term),
        ...this.params,
        ...this.getJavaPageOptions({ sort: ['mbhId,asc', 'validUntil,asc'] })
      }
    }
  },
  components: {
    SearchProductionReport,
    searchFarm,
    searchDairy,
    searchContact,
    searchLegalEntity
  },
  methods: {
    search() {
      this.$router
        .push({
          path: this.$route.path,
          query: this.query
        })
        .catch((e) => {
          // route duplicated
          this.load()
        })
    },
    async load() {
      this.loading = true
      try {
        this.items = []
        const response = await this.axios.get(apiURL + '/productionForms/reports/overview', {
          params: this.query
        })
        const items = await response.data.content
        this.items = Array.isArray(items) ? items : []
        this.totalElements = this.checkPage(response.data.totalElements)
      } catch (e) {
        showError(e)
      } finally {
        this.loading = false
      }
    },
    reset() {
      this.$refs['search-farm'].reset()
      this.$refs['search-dairy'].reset()
      this.$refs['search-contact'].reset()
      this.$refs['search-legal-entity'].reset()
      this.$refs['search-production-report'].reset()
    },
    async download() {
      this.loadingDownload = true
      try {
        const response = await this.axios.get(apiURL + '/productionForms/reports/overview', {
          params: { ...this.query },
          headers: {
            Accept: 'application/msexcel'
          },
          responseType: 'blob'
        })
        fileCreator(await response, 'mpd1_reports.xlsx')
      } catch (e) {
        let responseObj = await e.response.data.text()
        showError({ response: { data: JSON.parse(responseObj) } })
      } finally {
        this.loadingDownload = false
      }
    }
  },
  watch: {
    /* if someone changes the route by hand */
    '$route.query': {
      handler(newValue) {
        this.load()
      },
      deep: true
    }
  },
  mounted() {
    this.initialized = true
    if (this.$route.query.size) this.load()
  }
})
</script>
